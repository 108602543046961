import { SVGProps } from 'react';

export default function UserLocationDisabledIcon(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg viewBox="0 0 27 27" {...props}>
      <path d="M2.75202 14.4224L12.4372 14.4535C12.5954 14.4535 12.6351 14.5073 12.6351 14.6514L12.6662 24.2799C12.6803 26.3754 15.1823 26.7506 16.0802 24.8252L18.717 19.1722L17.1447 17.5999L14.7755 22.6977C14.7527 22.7573 14.7157 22.7515 14.7157 22.692L14.7608 15.2131L11.8472 12.2996L4.36843 12.3448C4.32012 12.3448 4.30601 12.2935 4.36268 12.2737L9.46913 9.93267L7.90515 8.36869L2.05189 11.0931C0.213408 11.9431 0.733093 14.4083 2.75202 14.4224ZM21.0423 14.1404L25.8929 3.68391C26.8166 1.72373 25.2659 0.320311 23.3117 1.22964L12.9229 6.06036L14.4981 7.63556L23.4933 3.51566C23.5701 3.47021 23.6242 3.50729 23.5646 3.60108L19.4418 12.5651L21.0423 14.1404ZM24.7142 23.0151C25.115 23.4159 25.7841 23.4159 26.1738 23.0151C26.5463 22.6142 26.5635 21.9564 26.1738 21.5527L5.6876 1.08068C5.28963 0.682691 4.62893 0.676942 4.22519 1.08068C3.83845 1.47031 3.83845 2.13935 4.22519 2.52609L24.7142 23.0151Z" />
    </svg>
  );
}
