import Image from 'next/image';
import Link from 'next/link';
import KebabPopover from '@/components/ui/header/KebabPopover';

export default function Header() {
  return (
    <header className="fixed top-0 z-50 flex h-14 w-full items-center bg-neutral-1000 px-4 md:h-12">
      <div className="flex w-full items-center justify-between">
        <div className="max-w-[144px] md:max-w-[175px]">
          <Link href="/network/coverage">
            <Image
              src="/images/logos/hivemapper.svg"
              width={212}
              height={32}
              priority
              alt="logo"
            />
          </Link>
        </div>
        <div className="hidden md:flex">
          <div className="border-r pl-4 pr-6 text-neutral-600">
            <Link href="/privacy/overview">Privacy</Link>
          </div>
          <KebabPopover />
        </div>
      </div>
    </header>
  );
}
