import { SVGProps } from 'react';

export default function CloseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M1.94859 1.91838C2.22333 1.64365 2.66876 1.64365 2.9435 1.91838L14.1996 13.1745C14.4744 13.4493 14.4744 13.8947 14.1996 14.1694C13.9249 14.4442 13.4795 14.4442 13.2047 14.1694L1.94859 2.9133C1.67385 2.63856 1.67385 2.19312 1.94859 1.91838Z" />
      <path d="M14.6537 1.83091C14.9284 2.10565 14.9284 2.55109 14.6537 2.82582L3.39753 14.082C3.1228 14.3567 2.67736 14.3567 2.40262 14.082C2.12788 13.8072 2.12789 13.3618 2.40262 13.087L13.6588 1.83091C13.9335 1.55618 14.3789 1.55618 14.6537 1.83091Z" />
    </svg>
  );
}
