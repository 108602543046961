import React, { SVGProps } from 'react';

export default function EllipseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="3"
      viewBox="0 0 10 3"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M7.89624 1.056C7.89624 0.912001 7.92291 0.776001 7.97624 0.648001C8.02957 0.520001 8.10157 0.408 8.19224 0.312C8.28824 0.216 8.40024 0.141334 8.52824 0.0880003C8.65624 0.0293336 8.79491 0 8.94424 0C9.08824 0 9.22424 0.0293336 9.35224 0.0880003C9.48024 0.141334 9.59224 0.216 9.68824 0.312C9.78424 0.408 9.85891 0.520001 9.91224 0.648001C9.97091 0.776001 10.0002 0.912001 10.0002 1.056C10.0002 1.20533 9.97091 1.344 9.91224 1.472C9.85891 1.6 9.78424 1.712 9.68824 1.808C9.59224 1.904 9.48024 1.97867 9.35224 2.032C9.22424 2.08533 9.08824 2.112 8.94424 2.112C8.79491 2.112 8.65624 2.08533 8.52824 2.032C8.40024 1.97867 8.28824 1.904 8.19224 1.808C8.10157 1.712 8.02957 1.6 7.97624 1.472C7.92291 1.344 7.89624 1.20533 7.89624 1.056Z" />
      <path d="M4.43481 1.056C4.43481 0.912001 4.46148 0.776001 4.51481 0.648001C4.56815 0.520001 4.64015 0.408 4.73081 0.312C4.82681 0.216 4.93881 0.141334 5.06681 0.0880003C5.19481 0.0293336 5.33348 0 5.48281 0C5.62681 0 5.76281 0.0293336 5.89081 0.0880003C6.01881 0.141334 6.13081 0.216 6.22681 0.312C6.32281 0.408 6.39748 0.520001 6.45081 0.648001C6.50948 0.776001 6.53881 0.912001 6.53881 1.056C6.53881 1.20533 6.50948 1.344 6.45081 1.472C6.39748 1.6 6.32281 1.712 6.22681 1.808C6.13081 1.904 6.01881 1.97867 5.89081 2.032C5.76281 2.08533 5.62681 2.112 5.48281 2.112C5.33348 2.112 5.19481 2.08533 5.06681 2.032C4.93881 1.97867 4.82681 1.904 4.73081 1.808C4.64015 1.712 4.56815 1.6 4.51481 1.472C4.46148 1.344 4.43481 1.20533 4.43481 1.056Z" />
      <path d="M0.973633 1.056C0.973633 0.912001 1.0003 0.776001 1.05363 0.648001C1.10697 0.520001 1.17897 0.408 1.26963 0.312C1.36563 0.216 1.47763 0.141334 1.60563 0.0880003C1.73363 0.0293336 1.8723 0 2.02163 0C2.16563 0 2.30163 0.0293336 2.42963 0.0880003C2.55763 0.141334 2.66963 0.216 2.76563 0.312C2.86163 0.408 2.9363 0.520001 2.98963 0.648001C3.0483 0.776001 3.07763 0.912001 3.07763 1.056C3.07763 1.20533 3.0483 1.344 2.98963 1.472C2.9363 1.6 2.86163 1.712 2.76563 1.808C2.66963 1.904 2.55763 1.97867 2.42963 2.032C2.30163 2.08533 2.16563 2.112 2.02163 2.112C1.8723 2.112 1.73363 2.08533 1.60563 2.032C1.47763 1.97867 1.36563 1.904 1.26963 1.808C1.17897 1.712 1.10697 1.6 1.05363 1.472C1.0003 1.344 0.973633 1.20533 0.973633 1.056Z" />
    </svg>
  );
}
