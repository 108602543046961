import { SVGProps } from 'react';

export default function HamburgerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <rect id="Rectangle 12" x="4" y="6" width="16" height="2" />
      <rect id="Rectangle 13" x="4" y="11" width="16" height="2" />
      <rect id="Rectangle 14" x="4" y="16" width="16" height="2" />
    </svg>
  );
}
