import { SVGProps } from 'react';

export default function BeeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M9.76817 19.1918H14.7763L7.26172 14.6881L9.76817 19.1918Z" />
      <path d="M19.7673 7.88532L18.4805 5.56613H21.3326C21.1603 5.25497 20.9119 4.99659 20.6126 4.81706C20.3133 4.63754 19.9737 4.54324 19.6281 4.54368H17.9139L17.0592 2.99755C16.8834 2.69138 16.6336 2.43826 16.3346 2.26328C16.0356 2.08831 15.6978 1.99757 15.3546 2.00005L16.7951 4.57361H14.1974L13.2995 6.15964C11.4378 5.83151 9.52397 6.10291 7.81506 6.9374C6.10615 7.77189 4.68396 9.12951 3.73945 10.828C3.44229 11.3679 3.19471 11.9356 3 12.5237H8.52188L15.9644 17.0125L18.7206 22C19.1123 21.5375 19.4596 21.0363 19.7577 20.5037C20.7012 18.805 21.1218 16.8471 20.963 14.8928C20.8042 12.9386 20.0737 11.0816 18.8694 9.5711L19.7673 7.88532ZM16.0316 14.5736L13.9285 13.3117L12.6033 12.5137L10.4761 11.2369L11.6429 9.14716L17.208 12.4838L16.0316 14.5736Z" />
    </svg>
  );
}
