import { cn } from '@/utils/styles';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function Container({ children, className }: Props) {
  return (
    <div
      id="container"
      className={cn(
        'container flex min-h-full flex-1 flex-col p-4',
        className,
      )}>
      {children}
    </div>
  );
}
