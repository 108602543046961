import Image from 'next/image';
import Link from 'next/link';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shadcn/popover';
import EllipseIcon from '../icons/EllipseIcon';

type Content = {
  title: string;
  url: string;
  icon?: { url: string; alt: string };
};

const content: Content[] = [
  {
    title: 'Use Cases',
    url: '/use-cases',
  },
  {
    title: 'Products',
    url: '/product',
  },
  {
    title: 'Contribute',
    url: '/contribute',
  },
  {
    title: 'Guilds',
    url: '/fleet-management',
  },
  {
    title: 'Blog',
    url: '/blog',
  },
  {
    title: 'Careers',
    url: '/careers',
  },
  {
    title: 'Terms of Service',
    url: '/tos/mapping-network/#introduction',
  },
  {
    title: 'Join the Community',
    url: 'https://discord.com/invite/FRWMKyy5v2',
    icon: { url: '/images/icons/discord.svg', alt: 'discord icon' },
  },
  {
    title: 'Follow Us',
    url: 'https://twitter.com/Hivemapper',
    icon: { url: '/images/icons/x.svg', alt: 'x icon' },
  },
];

export default function KebabPopover() {
  return (
    <Popover>
      <PopoverTrigger>
        <div className="py-2 pl-8 pr-6">
          <EllipseIcon className="fill-neutral-0" />
        </div>
      </PopoverTrigger>
      <PopoverContent className="mr-11 mt-5 w-[203px]">
        <div className="inline-flex flex-col justify-start gap-4 rounded">
          {content.map((item, index) => (
            <div
              key={index}
              className="inline-flex h-[18px] items-center justify-start gap-2 self-stretch">
              {item.icon && (
                <div className="relative size-4">
                  <Image
                    src={item.icon.url}
                    width={16}
                    height={16}
                    alt={item.icon.alt}
                  />
                </div>
              )}
              <div className="shrink grow basis-0 text-base font-normal capitalize leading-normal tracking-tight text-neutral-600">
                <Link href={item.url}>{item.title}</Link>
              </div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}
