import MainNav from '@/components/ui/navigation/MainNav';
import AdminNav from '../ui/navigation/AdminNav';
import Header from './Header';
import Container from './Container';

interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  return (
    <div className="flex size-full flex-col bg-neutral-800">
      <Header />
      <div className="flex size-full flex-1 pt-14 md:pt-12">
        <MainNav />
        <div className="size-full flex-1 md:pl-[232px]">
          <Container>{children}</Container>
        </div>
      </div>
    </div>
  );
}

export function TightLayout({ children }: Props) {
  return (
    <div className="flex size-full flex-col bg-neutral-800">
      <Header />
      <div className="flex size-full flex-1 pt-14 md:pt-12">
        <MainNav />
        <div className="size-full flex-1 md:pl-[232px]">{children}</div>
      </div>
    </div>
  );
}

export function BasicLayout({ children }: Props) {
  return (
    <div className="flex size-full flex-col bg-neutral-800">
      <div className="flex size-full flex-1">
        <Container>{children}</Container>
      </div>
    </div>
  );
}

export function LayoutWithHeader({ children }: Props) {
  return (
    <div className="flex size-full flex-col bg-neutral-800">
      <Header />
      <div className="flex size-full flex-1 pt-14 md:pt-12">
        <div className="md:hidden">
          <MainNav />
        </div>
        <Container>{children}</Container>
      </div>
    </div>
  );
}

export function AdminLayout({ children }: Props) {
  return (
    <div className="flex size-full flex-col bg-neutral-800">
      <Header />
      <div className="flex size-full flex-1 pt-14 md:pt-12">
        <AdminNav />
        <div className="flex size-full flex-1 md:pl-[232px]">
          <Container>{children}</Container>
        </div>
      </div>
    </div>
  );
}
